import React from "react"
import StoreIcon from "mdi-react/StoreIcon"

import ChurchIcon from "mdi-react/ChurchIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import mobileFood from "../../images/components/mobile-food.jpg"
import mobileFoodBody1 from "../../images/components/mobile-food-body-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./mobile-food-market-program.scss"

var MobileFoodMarketProgram = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="mobile-food-market-program-page">
      <Banner
        src={mobileFood}
        title="Mobile Food Market Program"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Mobile Food Market Program
        </BorderTitle>
        <div className="body">
          <img className="left" src={mobileFoodBody1} alt="" /><p>Every week, a truck carrying fruits and vegetables goes to four senior residence centers in Health Zone 1 to provide residents with healthy food options.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/pastor-mental-health-coach-training-program"
            className="first"
            icon={ChurchIcon}
            title="Pastor Mental Health Coach Training Program"
            />
          <ComponentNavigator
            next
            link="/components-year-two/corner-store-initiative"
            icon={StoreIcon}
            title="Corner-Store Initiative"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default MobileFoodMarketProgram
